<template>
<div>
	<div class="cover cover--image-bottom cover--home" :style="'background-image: url(' + require('@/assets/cover-home.jpg') + ')'">
		<div class="wrapper cover__content">
         <h1 class="cover__title">Time for a New Political<br>Alternative in B.C.?</h1>
         <h2 class="cover__subtitle">We think so. Sign up below if you do too.</h2>
         <form class="form form--boxless form--column" method="post" action="https://commonsensebc.com/webservices/forms.php">
            <div style="max-width: 400px; display: flex; flex-wrap: wrap;">
               <div class="form__item form__item--duo form__item--large"><input name="firstname" type="text" placeholder="First Name*" autocomplete="given-name" required/></div>
               <div class="form__item form__item--duo form__item--large"><input name="lastname" type="text" placeholder="Last Name*"  autocomplete="family-name" required/></div>
               <div class="form__item form__item--large"><input name="email" type="email" placeholder="Email Address*" autocomplete="email" required/></div>
               <div class="form__item form__item--large"><input name="zip" type="text" placeholder="Postal Code*" autocomplete="postal-code" pattern="[A-Za-z][0-9][A-Za-z][\s]?[0-9][A-Za-z][0-9]" required/></div>
            </div>
            <input type="hidden" name="source" value="commonsensebc_join"/>
            <input style="margin-top: 20px; max-width: 400px;" type="submit" class="button button--yellow button--large" value="Join" />
         </form>
		</div>
	</div>

   <div class="blue-box blue-box--transparent jumbotron" :style="'background-image: url(' + require('@/assets/cover-contact.jpg') + ')'">
      <div class="wrapper wrapper--spaced wrapper--centered">
         <h1 class="title title--white title--spaced">We are British Columbians –</h1>
         <p class="text">who believe this incredible province deserves better; who are unhappy with the current political choices and who believe it’s time for a real, democratic alternative.</p>
         <p class="text">Under the NDP, our economic strength has waned, our personal freedoms eroded and the cost of living has soared to once-unimaginable highs. And yet, there appears to be no genuine alternative; no democratic debate. No one standing up for the hard-working taxpayers of this province when it matters most. We believe the time is right for a new alternative to emerge.</p>
         <a href="/platform" class="button button--yellow button--large">Learn More</a>
      </div>
   </div>

   <div class="white-box">
      <div class="wrapper wrapper--spaced wrapper--separator">
         <div class="grid grid--gutter">
            <div class="grid__tile">
               <h1 class="title title--spaced">A Promise to You</h1>
               <p class="text">Following the undemocratic decision to block the BC Liberal leadership candidacy of Aaron Gunn, on November 5th, 2021, Aaron promised a new political alternative for B.C. by the time of the next election.</p>
               <p class="text">Backed by a team of committed and accomplished British Columbians, CommonSenseBC is the first step in the realization of that guarantee.</p>
            </div>
            <div class="grid__tile" style="display: flex; width: 100%;">
               <iframe width="100%" height="315" src="https://www.youtube.com/embed/1anP28l-rxw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
         </div>
      </div>

      <div class="wrapper wrapper--spaced">
         <div class="grid grid--gutter">
            <div class="grid__tile">
               <h1 class="title title--spaced">A Lack of<br>Common Sense</h1>
               <p class="text">Together, British Columbia’s political parties have enacted and supported policies that have raised the cost of living, increased crime and decimated BC’s resource industries.</p>
               <p class="text">We believe it’s time for a new approach.</p>
            </div>
            <div class="grid__tile">
               <img src="@/assets/home-jumbotron.jpg" alt=""/>
            </div>
         </div>
      </div>
   </div>

   <div class="blue-box blue-box--light jumbotron">
      <div class="wrapper wrapper--spaced wrapper--centered">
         <h1 class="title title--white title--spaced">Join our movement to bring common<br>sense back to British Columbia</h1>
         <form  class="form form--boxless form--column" style="display: inline-flex; margin:auto;" method="post" action="https://commonsensebc.com/webservices/forms.php">
            <div style="max-width: 600px; display: flex; flex-wrap: wrap;">
               <div class="form__item form__item--duo form__item--large"><input name="firstname" type="text" placeholder="First Name*" class="cover__input" autocomplete="given-name" required/></div>
               <div class="form__item form__item--duo form__item--large"><input name="lastname" type="text" placeholder="Last Name*" class="cover__input" autocomplete="family-name" required/></div>
               <div class="form__item form__item--duo form__item--large"><input name="email" type="email" placeholder="Email Address*" class="cover__input" autocomplete="email" required/></div>
               <div class="form__item form__item--duo form__item--large"><input name="zip" type="text" placeholder="Postal Code*" class="cover__input cover__input--small" autocomplete="postal-code" pattern="[A-Za-z][0-9][A-Za-z][\s]?[0-9][A-Za-z][0-9]" required/></div>
               <div class="form__item"><label class="option"><input type="checkbox" name="volunteer" value="yes"/> I would like to learn more about becoming a volunteer</label></div>
            </div>
            <input type="hidden" name="source" value="commonsensebc_join"/>
            <input style="max-width: 600px; margin-top: 20px;" type="submit" class="button button--yellow button--large" value="Register" />
         </form>
      </div>
   </div>

 </div>
</template>

<script>
import petitions from '@/petitions.js'
import facebookMixin from '@/videos-mixin.js'
export default {
	mixins: [facebookMixin],
	created () {

	},
	data() {
		return {
         petitions
		}
	},
	methods: {

	},
	computed: {
      getVideoComputed () {
         return this.getFbVideo(524373915568800);
      }
	}
}
</script>

<style scoped>
.petition-main { padding: 40px; padding-left: 50%; color: white; }
.petition-main__title { text-transform: uppercase; font-size: 2rem; font-weight: 600; }
.petition-main__description { padding: 20px 0; font-weight: 300; }

.petitions { list-style: none; display: flex; padding: 30px 0; }
.petitions__item { flex: 1; padding-left: 40px; }
.petitions__item:first-child { padding-left: 0; }
.petitions__image { max-width: 100%; height: 190px; object-fit: cover; vertical-align: top; }
.petitions__title { text-transform: uppercase; font-size: 2rem; font-weight: 600; color: #062165; padding: 10px 0; }
.petitions__link { color: #E0231C; }

.events { list-style: none; }
.events__item { display: flex; margin-bottom: 20px; }
.events__content { background: white; color: #7282AA; }
.events__content:first-child { padding: 40px 50px; width: 70%; }
.events__content:last-child { padding: 40px 60px; margin-left: 2px; width: calc(40% - 2px); text-align: center; display: flex; flex-direction: column; justify-content: center; }
.events__location { display: flex; align-items: flex-end; margin-top: 20px; }
.events__icon { width: 40px; }
.events__city { text-transform: uppercase; color: #E0231C; font-weight: bold; font-size: 2rem; }
.events__date { color: #062573; font-size: 2rem; font-weight: 600; }

.video { width: 100%; max-width: 625px; height: 350px; margin-bottom: 20px; display: flex; align-items: flex-end; position: relative; overflow: hidden; z-index: 0; }
.video::before { content: " "; position: absolute; background: linear-gradient(to bottom, transparent, black); width: 100%; height: 100%; z-index: -1; }
.video__image { position: absolute; top: 0; z-index: -2; }
.video__play { margin: 20px; }

.politics-explained-text { width: 30%; margin-top: 40px; }

@media (max-width: 1100px) {
   .petition-main { padding-left: 40px; }
   .petitions { flex-direction: column; }
   .petitions__item { padding-left: 0; text-align: center; margin-bottom: 20px; }
   .petitions__image { width: 100%; }

   .video { height: 200px; }

   .politics-explained-text { width: 100%; margin-top: 40px; }
}

@media (max-width: 768px) {

}

@media (max-width: 526px) {

}

</style>

export default [
   {
      "url": "stop-tearing-down-history",
      "isFeatured": true,
      "title": "Stop Tearing Down Our History",
      "descriptionShort": "Statues of Canada’s first Prime Minister, Sir. John A. Macdonald, and other historical figures, are an important part of our history and culture. Politicians need to leave them alone.",
      "descriptionHtml": "<p class='text'>In 2018, Victoria Mayor Lisa Helps and her cronies on City Council announced they would tear down a statue of Canada’s first Prime Minister, Sir. John A. Macdonald, without public consultation and without public debate.</p> <p class='text'>Just 48 hours later, the statue was gone. </p> <p class='text'>Is this really what political correctness has come to? Expunging our history? Erasing our past? Toppling a monument to the father of confederation, who united us, built our country and delivered on the audacious promise of a national railway?  </p> <p class='text'>And activists haven’t stop there. </p> <p class='text'>Continuing their calls for statues to be removed across the country in 2020, including George Vancouver’s, Matthew Begbie’s and even Winston Churchill’s.</p> <p class='text'>This unilateral uprooting of Canada’s historic monuments and national symbols is wrong.</p> <p class='text text--bold'>We, the undersigned, call on Canada’s politicians to reject the example set by the City of Victoria, and leave Canada’s history, and monuments, alone. </p>",
      "sourceId": "petition2"
   },
   {
      "url": "scrap-carbon-tax",
      "isFeatured": false,
      "title": "Scrap the Carbon Tax!",
      "descriptionShort": "Hard-working Canadians are already being punished enough at the pumps. The last thing they need is a double dosing from their own government.",
      "descriptionHtml": "<p class='text'>On December 11 th, 2020, the Trudeau government announced they would be breaking their promise from 2019 and increasing the much - maligned federal Carbon Tax by a further 500% to $170/ton.</p><p class='text'>Leading to gas tax increase of, at minimum, 37 cents/litre, not to mention massive increases for Canadians using natural gas to heat their homes .</p><p class='text'>This is insane.</p><p class='text'>Excise taxes, transit taxes, sales taxes and now carbon taxes together inflate the price of gas already by as much as 50 cents/litre.</p><p class='text'>And while many of these levies are, at least in theory, supposed to go toward maintaining roads and building transit, the Carbon Tax, on the other hand, has the sole purpose of punishing you for driving.</p><p class='text'>Someone driving themselves to work or simply heating their home should not be the target of government punishment and contempt. </p> <p class='text text--bold'>We, the undersigned, call on the provincial and federal governments to freeze and eventually scrap the punitive Carbon Tax. With gas prices substantially higher than in the United States, hard - working Canadians are already being punished enough at the pumps. The last thing they need is a double dosing from their own government.</p>",
      "sourceId": "petition7"
   },
   {
      "url": "protect-freedom-speech",
      "isFeatured": false,
      "title": "Protect Freedom of Speech in Canada",
      "descriptionShort": "Bill C-10 from the Trudeau Government poses an existential threat to free speech and free expression. It must be stopped.",
      "descriptionHtml": "<p class='text'>The latest legislation from the Trudeau Government, Bill C-10, is a dangerous infringement on the fundamental rights of all Canadians.</p><p class='text'>Specifically, its provisions to regulate user-generated video content on social media sites such as YouTube and Facebook.</p><p class='text'>A move former CRTC commissioner Peter Menzies called a “full blown assault” on the “foundations of democracy”.</p><p class='text text--bold'>We, the undersigned, call on the Trudeau government to immediately scrap (or seriously reform) Bill C-10. Free speech and free expression form the foundation on which our democracy is built.</p><p class='text text--bold'>They must be protected.</p>",
      "sourceId": "petition12"
   },
   {
      "url": "huawei-out-of-canada",
      "isFeatured": false,
      "title": "Keep Huawei out of Canada",
      "descriptionShort": "Companies like Huawei, that are controlled by or beholden to the Chinese Communist Party, must not be allowed to infect Canada’s critical telecom infrastructure.",
      "descriptionHtml": "<p class='text'>It’s time for Victoria City Councillor Ben Isitt to resign. </p><p class='text'>Over the past two years, Isitt has embarrassed the city (and himself) over and over again. Ignoring the concerns of his constituents in order to pursue his own radical ideology. </p><p class='text' style='padding-bottom: 0;'><b>In just the past 24 months he has:</b></p><ul class='text' style='margin-left:20px'><li>Cheered the removal Sir John A. Macdonald statue at City Hall</li><li>Targeted Victoria’s Christmas decorations (including Christmas trees and poinsettias) for their potential to be exclusive and/or offensive</li><li>Supported an attempt to sue oil and gas companies for the hypothetical costs of climate change</li><li>Attacked the city’s iconic horse-drawn carriages and attempted to shut them down</li><li>Insulted Canada’s veterans, by referring to the Invictus Games as “arguably… glorifying militarism”</li><li>Spearheaded an effort to defund the city’s Remembrance Day service by withholding their police budget</li><li>Blamed criticism of his actions on the “corporate media”, “conservative political organizations”, and even the “alt-right”</li><li>Told the CBC the real lesson of World War II was one “of fighting back against conservative forces”</li></ul><p class='text'>Enough is enough.</p><p class='text text--bold'>We, the undersigned, call on Victoria City Councillor Ben Isitt to resign his position immediately. His actions do not represent the will of his constituents nor the platform he campaigned on.</p><p class='text text--bold'>Victorians deserve a representative who addressees the concerns of the community – not one who pursues his own narrow ideology at everyone else’s expense.  </p>",
      "sourceId": "petition10"
   },
   {
      "url": "end-icbc-monopoly",
      "isFeatured": false,
      "title": "End the ICBC Monopoly",
      "descriptionShort": "British Columbians pay more for auto insurance than anywhere else in the country. It’s time for the government monopoly to go. ",
      "descriptionHtml": "<p class='text'>It’s time to end the ICBC monopoly. </p> <p class='text'>The current approach is just not working. </p> <p class='text'>Since 2012, rates for BC drivers have skyrocketed over 60%.</p> <p class='text'>Leaving British Columbians paying hundreds of dollars more for their auto insurance than anywhere else in the country. </p> <p class='text'>On top of all this, ICBC has cost taxpayers more than $3 billion in the last three years alone – a bill that taxpayers can’t and shouldn’t be forced to pay.</p><p class='text'>The truth is, ICBC is an ineffective, inefficient government monopoly that’s rotten to its core. </p><p class='text'>It’s time for a change. </p><p class='text text--bold'>We, the undersigned, call on the government to open ICBC up to competition immediately. To allow British Columbians the freedom to shop around for the best possible coverage, at the best possible price, and to finally get the cost of auto insurance under control.  </p>",
      "sourceId": "petition4"
   },
   {
      "url": "lifetime-allowance-governor-general",
      "isFeatured": false,
      "title": "End Lifetime Expense Allowance for Governor Generals",
      "descriptionShort": "Everyday Canadians don’t get to keep claiming expenses after they’ve left their jobs. So why are we still paying the Governor General?",
      "descriptionHtml": "<p class='text'>After leaving office, Governor Generals in Canada continue to receive $100,000 in expenses each year, paid for by taxpayers, until the day they die.</p><p class='text'>This despite having no remaining role or responsibilities with the Canadian government. </p><p class='text'>What is more, the details of these expenses are completley unknown and secret from Canadian taxpayers - even though they’re the ones paying the bills.</p><p class='text'>This is fundamentally unfair to the millions of Canadians who work hard for their money, and a gross abuse of taxpayer dollars.</p><p class='text text--bold'>We, the undersigned, call on the Trudeau Government to rescind this practice immediately, and to exercise better caution when spending taxpayer money in the future.</p>",
      "sourceId": "petition11"
   },
   // {
   //    "url": "high-bc-cost-living",
   //    "isFeatured": false,
   //    "title": "Cost of Living in B.C. is Out of Control",
   //    "descriptionShort": "Thanks to recent tax changes and rate hikes, British Columbia has become an even less affordable place to call home. It’s time our politicians get the message.",
   //    "descriptionHtml": "<p class='text'>B.C. is becoming an unaffordable place to live. </p><p class='text'>In 2019, gas prices broke record highs again, while at ICBC rates increased a further 6.3% and are up nearly 60% since 2012. </p><p class='text'>Combine all this with an out-of-control real estate market and it’s a wonder anyone can afford to live here at all. </p><p class='text'>Even the cost of hydroelectricity, once a bragging point of British Columbians, has increased dramatically over the past few years. </p><p class='text'>It continues a trend in this province, where instead of making BC more affordable, the government is doing the exact opposite – raising taxes and hiking rates on what are basic life necessities for most individuals and families. </p><p class='text text--bold'>We, the undersigned, call on the provincial government to immediately reverse all recent tax changes and rate hikes that have made British Columbia a less affordable place to call home. Because it’s hard to enjoy the beauty of B.C., when you can’t afford to live here.</p>",
   //    "sourceId": "petition8"
   // },
   {
      "url": "leave-our-anthem-alone",
      "isFeatured": false,
      "title": "Leave Canada’s Anthem Alone",
      "descriptionShort": "Canada’s national anthem is part of our country’s history and identity. It belongs to the Canadian people - not our politicians.",
      "descriptionHtml": "<p class='text'>Dear Prime Minister Trudeau,</p> <p class='text'>Canada’s national anthem is part of our country’s history and identity.</p> <p class='text'>The line, “In all thy sons command”, was introduced to honour the young Canadian soldiers sent off to war in 1914 - 59,00 of whom would never return.</p> <p class='text'>Changes to national symbols, whether they be flags or anthems, should require the approval of the people in a referendum.</p> <p class='text'>Your unilateral change of the anthem’s lyrics was wrong.</p> <p class='text text--bold'>We, the undersigned, call on the Government of Canada to revert the anthem’s lyrics to the version passed unanimously by Parliament in 1980, and require all future changes to be approved by the people in a referendum.</p>",
      "sourceId": "petition1"
   },
   {
      "url": "support-canadian-resources",
      "isFeatured": true,
      "title": "Support Canadian Resources",
      "descriptionShort": "Canadian resources are the backbone of our economy. It’s time our politician give them the respect that they deserve.",
      "descriptionHtml": "<p class='text'>Canadian resources are the backbone of our economy.  </p><p class='text'>Each year, the oil and gas sector alone contributes an estimated $15 billion to government tax revenues here in Canada.</p><p class='text'>This in addition to supporting hundreds of thousands of well-paying jobs.  </p><p class='text'>But instead of standing up for the industry’s development, the majority of our politicians seem to be actively plotting against it. </p><p class='text'>The truth is, the world will continue to need and demand resources like oil and gas for decades to come. </p><p class='text'>Resources that could come from brutal, repressive regimes like Iran and Saudi Arabia - or that could come from here in Canada.</p><p class='text text--bold'>We, the undersigned, call on the federal and provincial governments to support the responsible development of Canadian resources, including the construction of pipelines, and to abandon onerous and discriminatory legislation like bill C-48 that continues to strangle this country’s most important industry. </p>",
      "sourceId": "petition6"
   },
   // {
   //    "url": "ben-isitt-resign",
   //    "isFeatured": false,
   //    "title": "Tell Ben Isitt, It’s Time to Resign",
   //    "descriptionShort": "Over the past two years, Isitt has embarrassed the city (and himself) over and over again. It’s time for him to do the honourable thing and resign.",
   //    "descriptionHtml": "",
   //    "sourceId": "petition9"
   // },
]